import { graphql, PageProps, StaticQuery } from "gatsby";
import React, { Children, useEffect, useMemo } from "react";
import { TFunction } from "react-i18next";
import Navbar from "../components/Navbar/Navbar";
import PortfolioCommercial, {
	PortfolioCommercialProps,
} from "../components/PortfolioCommercial/PortfolioCommercial";
import SwipeableLinkDesktop from "../components/SwipeableLink";
import useMediaQuery from "../hooks/useMediaQuery";
import { useTranslation } from "../i18n";
// @ts-ignores
import ProjectIntelPic from "./project_intel.webp";

type Portfolios = {
	[K: string]: PortfolioCommercialProps;
};

interface Context {
	links: {
		forward: string;
		back: string;
	};
	coverPicUrl: string;
	videoUrl: string;
	title: string;
	titleJapanese: string;
	innerHTML?: string;
	innerHTMLJapanese?: string;
}

interface Props { }

export default function portfolioPageTemplate({
	pageContext,
}: PageProps<Props, Context>) {
	const { i18n } = useTranslation();
	const showDesktop = useMediaQuery("(min-width: 1024px)");

	const SwipeableLink = useMemo(() => showDesktop ? SwipeableLinkDesktop : React.Fragment, [showDesktop]);

	return (
		<SwipeableLink
			left={pageContext.links.forward || "#"}
			right={pageContext.links.back || "#"}
		>
			<div className="bg-tofuLightGrey h-full mt-4">
				<Navbar />

				<div className="w-[100%] h-auto items-center flex-col flex">
					<PortfolioCommercial
						coverPicUrl={pageContext.coverPicUrl}
						videoUrl={pageContext.videoUrl}
						links={pageContext.links}
						title={
							i18n.language === "jp"
								? pageContext.titleJapanese
								: pageContext.title
						}
						innerHTML={
							i18n.language === "jp"
								? pageContext.innerHTMLJapanese
								: pageContext.innerHTML
						}
					/>
				</div>
			</div>
		</SwipeableLink>
	);
}
