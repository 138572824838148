import React, { useEffect, useRef, useState } from "react"

// Icons import
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md"

// Video wrapper & video.js
import videojs, { VideoJsPlayer } from "video.js"
import VREPlayer from "videojs-react-enhanced"
import "video.js/dist/video-js.css"
import "./player.css"
import ScrollContainer from "react-indiana-drag-scroll"

// Arrow image import
// @ts-ignore
import Arrow from "../../assets/arrows/sendButtonArrow.svg"
import { FaPause, FaPlay } from "react-icons/fa"
import { PortfolioCommercialProps } from "./PortfolioCommercial"
import { useTheme } from "../ThemeProvider/ThemeProvider"
import { Link } from "gatsby"
import { IoIosArrowBack } from "react-icons/io"
import ArrowIcon from "./ArrowIcon"
import { useTranslation } from "../../i18n"
import PlayButton from "./playButton.svg"

export const PortfolioCommercialDesktop: React.FC<PortfolioCommercialProps> = ({
	children,
	coverPicUrl,
	videoUrl,
	title,
	links,
	innerHTML,
}) => {
	const { colorMode } = useTheme()
	const [player, setPlayer] = useState<VideoJsPlayer>()

	const { t } = useTranslation()

	const [coverOpacity, setCoverOpacity] = useState(0)
	const scrollContainerRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		scrollContainerRef.current?.addEventListener(
			"scroll",
			// @ts-ignore
			(e: React.UIEvent<HTMLDivElement>) => {
				const visibleWidth = e.currentTarget?.clientWidth
				const totalWidth = e.currentTarget.scrollWidth
				const scrolled = e.currentTarget.scrollLeft
				const amountScrolled = scrolled / (totalWidth - visibleWidth)
				setCoverOpacity(amountScrolled * 0.6)
			}
		)
	}, [scrollContainerRef])

	// true - playing, false - paused, undefined - not started
	const [isPlaying, setPlaying] = useState<boolean | undefined>(undefined)

	const play = () => {
		if (player) {
			player.play()
			setPlaying(true)
		}
	}

	const pause = () => {
		if (player) {
			player.pause()
			setPlaying(false)
		}
	}

	const togglePlayback = () => {
		if (isPlaying) {
			pause()
		} else {
			play()
		}
	}

	const playerOptions: VREPlayer.IPlayerOptions = {
		src: videoUrl,
		controls: false,
	}
	const videojsOptions: VREPlayer.IVideoJsOptions = {
		fluid: true,
		nativeControlsForTouch: true,
		aspectRatio: "16:9",
	}

	// We want to make the playing window smaller as the window height decreases.
	// This code makes sure it fits.
	const maximumAllowedWidth = window.innerWidth * (1 - 2 * 0.07)
	const desiredWidth = Math.min(
		(window.innerHeight / 9) * 16 - 80,
		maximumAllowedWidth
	)
	const forceSmall = window.innerHeight < 850

	return (
		<div
			className="flex flex-row justify-between mx-[auto] max-w-[2100px] relative overflow-hidden"
			style={{
				width: forceSmall ? desiredWidth : "100%",
			}}
		>
			<div className="fixed flex items-center justify-center left-0 w-[7%] mt-[90px] h-screen">
				<Link to={links.back}>
					<ArrowIcon color={colorMode === "light" ? "bg-black" : "bg-white"} />
				</Link>
			</div>

			<div className="flex justify-center items-center w-[7%] mt-[90px] h-screen" />

			<div
				className={`z-40 absolute top-[90px] left-[7%] right-[7%] aspect-video duration-75 ${
					isPlaying && "hidden"
				}`}
			>
				<ScrollContainer
					hideScrollbars={false}
					vertical={false}
					className="w-[100%] aspect-video hover:cursor-grab"
					innerRef={scrollContainerRef}
				>
					<div className="flex flex-row w-[150%] h-[100%] mr-96">
						<div className="h-[80%] w-[46%] flex flex-row items-end snap-start">
							<div className="ml-16 text-white w-max mr-5 text-xl xl:text-[21.33px] 3xl:text-3xl font-syne">
								{t("drag_left")}
							</div>
							<img
								className="h-3 mb-3"
								src={Arrow}
								alt="An arrow pointing right"
							/>
						</div>
						<div className="flex items-center justify-center w-[8%] ml-[4vw] h-[100%]">
							<div onClick={togglePlayback}>
								{isPlaying && <FaPause color="white" size={35} />}
								{!isPlaying && (
									<img
										className=""
										src={PlayButton}
										alt="Play button"
									/>
								)}
							</div>
						</div>
						<div className="flex justify-end items-center w-[50%] h-[95%]">
							<p
								className={`font-syne w-max h-[95%] ${
									forceSmall ? "text-2xl" : "text-4xl"
								} mt-12 font-bold text-white rotate-180`}
								style={{
									writingMode: "vertical-rl",
									textOrientation: "sideways",
								}}
							>
								{title}
							</p>
						</div>
						<div className="w-[16%]"></div>
						<div className="text-white text-xl font-medium leading-tight w-[45%] h-[90%] flex flex-col justify-end items-start snap-end">
							<div
								className="w-[120%] scrollBar flex flex-col mt-20 px-5 overflow-y-auto "
								dangerouslySetInnerHTML={
									innerHTML ? { __html: innerHTML } : undefined
								}
							>
								{children}
							</div>
						</div>
					</div>
				</ScrollContainer>
			</div>

			{isPlaying === undefined && (
				<div className="absolute top-[90px] left-[7%] right-[7%] aspect-video mb-10 z-20 bg-black">
					<img
						src={coverPicUrl}
						className="h-full w-full object-contain"
						alt="One frame of our video"
					/>
				</div>
			)}

			{isPlaying !== true && (
				<div
					style={{
						opacity: coverOpacity,
					}}
					className={`absolute top-[90px] left-[7%] right-[7%] aspect-video mb-10 z-30 bg-black`}
				/>
			)}

			<div
				className="absolute top-[90px] left-[7%] right-[7%] aspect-video mb-10"
				onClick={() => {
					isPlaying && pause()
				}}
			>
				<VREPlayer
					playerOptions={playerOptions}
					videojsOptions={videojsOptions}
					onReady={(player) => setPlayer(player)}
					onEnded={(e, _) => setPlaying(undefined)}
					hideList={["pictureInPictureToggle"]}
				/>
			</div>

			<div className="fixed right-0 flex justify-center items-center w-[7%] mt-[90px] h-screen">
				<Link to={links.forward}>
					<ArrowIcon
						right
						color={colorMode === "light" ? "bg-black" : "bg-white"}
					/>
				</Link>
			</div>
		</div>
	)
}
