import React, { useEffect, useRef, useState } from "react"

// Icons import
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md"

// Video wrapper & video.js
import { VideoJsPlayer } from "video.js"
import VREPlayer from "videojs-react-enhanced"
import "video.js/dist/video-js.css"
import "./player.css"

// Arrow image import
// @ts-ignore
import Arrow from "../../assets/arrows/arrowWhite.png"
import { FaPause, FaPlay } from "react-icons/fa"
import PlayButton from "./playButton.svg"
import { useTheme } from "../ThemeProvider/ThemeProvider"
import { PortfolioCommercialProps } from "./PortfolioCommercial"
import { Link } from "gatsby"
import { useWindowScrollPosition } from "rooks"

export const PortfolioCommercialMobile: React.FC<PortfolioCommercialProps> = ({
	children,
	coverPicUrl,
	videoUrl,
	title,
	links,
	innerHTML,
}) => {
	const { scrollY } = useWindowScrollPosition()

	const animLimit = 250
	const animDone = (scrollY > animLimit ? animLimit : scrollY) / animLimit

	const videoMaxWidth = 100 // in %

	return (
		<div className="relative flex flex-col justify-between w-[90%] mx-auto mt-24 bg-tofuLightGrey">
			<div className="absolute w-full top-0">
				<div
					className="fixed w-[90%] right-[5%] left-[5%]"
					style={{ zIndex: 40, opacity: 1 - animDone * 1.3 }}
				>
					<Arrows links={links} />
				</div>
			</div>

			<div style={{ width: "100%", height: 24 }} />
			<div className="mb-6" />

			<div className="relative mb-10">
				<div className="absolute mb-10">
					<div className="fixed w-[90%] left-[5%] right-[5%]">
						<div
							className="relative w-full aspect-video"
							style={{
								opacity: 1 - animDone,
								left: `-${(animDone * videoMaxWidth) / 2}%`,
								right: `${(animDone * videoMaxWidth) / 2}%`,
								width: `${100 + videoMaxWidth * animDone}%`,
							}}
						>
							<Player src={videoUrl} coverSrc={coverPicUrl} />
						</div>
					</div>
				</div>

				<div className="w-[100%] aspect-video" />
			</div>

			<div
				className="flex flex-col justify-center align-center text-tofuLightWhite"
				style={{ zIndex: 10 }}
			>
				<p className="font-syne text-2xl font-semibold leading-7 mb-5">
					{title}
				</p>
				<div className="w-[16%]"></div>
				<div
					className="leading-5 text-lg"
					dangerouslySetInnerHTML={
						innerHTML ? { __html: innerHTML } : undefined
					}
				>
					{children}
				</div>
			</div>
		</div>
	)
}

interface VideoProps {
	src: string
	coverSrc: string
}

const Player: React.FC<VideoProps> = ({ src, coverSrc }) => {
	const [player, setPlayer] = useState<VideoJsPlayer>()

	// true - playing, false - paused, undefined - not started
	const [isPlaying, setPlaying] = useState<boolean | undefined>(undefined)

	const play = () => {
		if (player) {
			player.play()
			setPlaying(true)
		}
	}

	const pause = () => {
		if (player) {
			player.pause()
			setPlaying(false)
		}
	}

	const togglePlayback = () => {
		if (isPlaying) {
			pause()
		} else {
			play()
		}
	}

	return (
		<>
			<VREPlayer
				playerOptions={{
					src,
					controls: true,
				}}
				videojsOptions={{
					fluid: true,
					nativeControlsForTouch: true,
					aspectRatio: "16:9",
				}}
				onReady={(player) => setPlayer(player)}
				onPause={() => setPlaying(false)}
				onEnded={() => setPlaying(undefined)}
				hideList={["pictureInPictureToggle"]}
			/>
			{isPlaying === undefined && (
				<div onClick={togglePlayback} className="aspect-video absolute top-0">
					<img
						className="aspect-video"
						src={coverSrc}
						alt="One frame of our video"
					/>
					<div className="absolute top-0 flex items-center justify-center w-[100%] aspect-video">
						{isPlaying && <FaPause color="white" size={35} />}
						{!isPlaying && (
							<img style={{ width: 21 }} src={PlayButton} alt="Play button" />
						)}
					</div>
				</div>
			)}
		</>
	)
}

interface ArrowsProps {
	links: PortfolioCommercialProps["links"]
}

const Arrows: React.FC<ArrowsProps> = ({ links }) => {
	const { colorMode } = useTheme()

	return (
		<div className="w-full flex flex-row justify-evenly align-center mb-6">
			<div className="flex justify-center items-center">
				<Link to={links.back}>
					<MdArrowBackIos
						size={24}
						color={colorMode === "light" ? "black" : "white"}
					/>
				</Link>
			</div>

			<div className="flex justify-center items-center">
				<Link to={links.forward}>
					<MdArrowForwardIos
						size={24}
						color={colorMode === "light" ? "black" : "white"}
					/>
				</Link>
			</div>
		</div>
	)
}
