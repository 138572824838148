import React from "react"
import useMediaQuery from "../../hooks/useMediaQuery"
import Footer from "../Footer/Footer"
import { PortfolioCommercialDesktop } from "./desktop"
import { PortfolioCommercialMobile } from "./mobile"

export type PortfolioCommercialProps = React.PropsWithChildren<{
	coverPicUrl: string
	videoUrl: string
	title: string
	links: { back: string; forward: string }
	innerHTML?: string
}>

const PortfolioCommercial: React.FC<PortfolioCommercialProps> = (props) => {
	const showDesktop = useMediaQuery("(min-width: 1024px)")

	return (
		<>
			<div className={`font-syne w-[100%]`}>
				{showDesktop ? (
					<PortfolioCommercialDesktop {...props} />
				) : (
					<PortfolioCommercialMobile {...props} />
				)}

				<div className={showDesktop ? "pb-32" : "pb-12"} />
				{!showDesktop && <Footer />}
			</div>
		</>
	)
}

export default PortfolioCommercial
